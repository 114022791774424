@import url('https://fonts.cdnfonts.com/css/sf-pro-display');

body {
  margin: 0;
  font-family: 'Roboto', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

#root {
  height: 100%;
}

::-webkit-scrollbar {
  padding: 0px 4px;
  width: 8px;
  height: 8px;
}

::-webkit-scrollbar-thumb {
  background-color: #C1C1C1;
  border-radius: 99px;    
}

.image{
  box-shadow: 6px 6px 0 0 #5277A8, -6px -6px 0 0 #D96868;
  border-radius: 6px;
  max-width: 196px;
}
.swiper_img{
  border-radius: 50%;
  object-fit: cover;
}




